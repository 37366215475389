html, body {
  font-family: "Roboto", sans-serif;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* z-index: -150; */
  background-image: url('/public/img/House-of-Commons.jpg');
}

.bevan-regular {
  font-family: "Bevan", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 4rem;
  color: #18555f;
}

.bevan-regular-italic {
  font-family: "Bevan", serif;
  font-weight: 600;
  font-style: italic;
}

.biryani-bold {
  font-family: "Biryani", sans-serif;
  font-weight: 700;
  font-style: normal;
}

h2 {
  font-family: "Bevan", serif;
  font-size: 3rem;
  color: #18555f;
  transform: rotate(-2deg);
  text-shadow: 4px 4px 4px rgba(148, 144, 144, 0.5);
  margin-bottom: 50px;
}

.game-win-modal-content {
  transform: rotate(2deg);
  background-color: rgb(242, 198, 234);
  width: 30vw;
  height: 50vh;
  padding: 75px;
  margin: 150px 0 0 250px;
  align-items: center;
  align-content: center;
  text-align: center;
  border-radius: 40px;
  box-shadow: 20px 20px 20px rgba(30, 31, 30, 0.721); /* Add this line */
}

.interim-win-modal-content {
  transform: rotate(2deg);
  background-color: rgb(242, 198, 234);
  width: 30vw;
  height: 50vh;
  padding: 75px;
  margin: 150px 0 0 250px;
  align-items: center;
  align-content: center;
  text-align: center;
  border-radius: 40px;
  box-shadow: 20px 20px 20px rgba(30, 31, 30, 0.721); /* Add this line */
}

/* MODAL */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}


.modal-content {
  background-color: rgb(198, 242, 212);
  width: 50vw;
  height: 50vh;
  padding: 75px;
  align-items: center;
  align-content: center;
  text-align: center;
  border-radius: 40px;
  box-shadow: 20px 20px 20px rgba(30, 31, 30, 0.721); /* Add this line */
}

.modal-instructions {
  line-height: 1.5rem;  /* Adjusted from 1.rem for clarity */
  font-size: 1.3rem;
  text-align: center;  /* Keep the overall instructions centered */
}

.modal-heading2 {
  font-size: 3.7rem;
  font-family: "Bevan";
  font-weight: 700;
  font-style: normal;
  margin: 0px 0 30px 0;
  color: yellow;
  text-shadow: 
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000,
    0px 3px 5px rgba(0,0,0,0.5),
    0 0 10px rgba(26, 6, 6, 0.7);
}

.modal-heading3 {
  font-size: 3rem;
  color: #18555f;
  transform: rotate(-2deg);
  text-shadow: 4px 4px 4px rgba(148, 144, 144, 0.5);
  font-family: "Alegreya Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-style: italic;
  margin-bottom: 30px;
}

.modal-heading4 {
  font-size: 1.4rem;
  color: #18555f;
  font-family: "Biryani", sans-serif;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 10px;
}

.modal-instructions-block {
  font-family: "Biryani", sans-serif;
  font-weight: 700;
  font-style: normal;
  display: inline-block;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  /* padding-left: 20px;  Adjust as needed */
  font-size: 1.1rem;
  width: 30vw;
}

.modal-instructions-block ol {
  line-height: 1.5rem;
}

.modal-instructions-block li {
  margin: 0 0 6px 0;
}

.modal {
  font-size: 1.6rem;
  color: #103a40;
  user-select: none; 
  transform: rotate(12deg);
}

.modal-text {
  font-family: "Biryani", sans-serif;
  font-weight: 300;
  color: #103a40;
}

.modal-text-credits {
  font-family: "Biryani", sans-serif;
  font-size: 1.0rem;
  padding: 0px 50px;
}

.modal-text-credits .smallish {
  font-size: 0.8rem;
}

.modal-text-credits .smaller {
  font-size: 0.75rem;
}

.modal-text-credits .smallest {
  font-size: 0.65rem;
}

.modal-text-credits a {
  font-family: "Biryani", sans-serif;
  text-decoration: underline;
  color: #103a40;
} 

.modal-text-credits-small {
  font-size: 0.8em;
}

.modal-text-credits-tiny {
  font-size: 0.7em;
}

.modal-text-credits a:hover {
  color: green
}

.modal-content, .modal-text {
  text-align: center;
}

.lucy-credits {
  line-height: 1.0rem;
}

.grid-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
}
  
.grid {
    display: grid;
    grid-template-columns: repeat(5, minmax(20%, 1fr));
    grid-template-rows: repeat(4, minmax(25%, 1fr));
    height: 85vh;
    width: 90vh;
}

.titleBar {
  /* position: absolute; */
  padding-left: 250px;
  top: 5px;
  right: 0;
  text-align: center;
  opacity: 100%;
  transform: rotate(2deg);
  text-shadow: 5px 5px 5px rgba(255, 255, 255, 0.5);
}


.square {
  position: relative;
  /* background-color: white; */
  border: 2px solid rgb(222, 218, 218);
  height: 95%;
  width: 95%;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  border-radius: 10px;
  z-index: 100;
}

.new-div {
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 2rem;
}

.stamp-container,
.mp-image-container {
  flex: 1;
  height: 100%;
}

.stamp-container {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('/public/img/stamp.png');
  background-size: 90% 75%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 2;
  opacity: 80%; 
}

.stamp-container-hidden {
  visibility: hidden;
}

.mp-image-container {
  opacity: 100%;
}

.mp-image {
  position: absolute;
  display: block;
  min-width: 100%;
  border-radius: 10px;
  z-index: -5;
}

.stamp-image {
  z-index: 50;
  width: 10%;
  height: 10%;
  border-radius: 10px;
  background: transparent;
}

.name-banner {
  font-family: "Jost", sans-serif;
  position: absolute;
  background-color: grey;
  z-index: 50;
  bottom: 10px;
  width: 95%;
  min-height: fit-content;
  transform: rotate(-2deg);
  border: 3px solid rgba(251, 241, 227, 0.5);
  border-radius: 5px;
  box-sizing: border-box;
  color: white;
  margin: 0 5px;
  padding: 2px;
  z-index: -5
}

.name-banner-Conservative {
  background-color: #4DABE9;
  color: #1A3A78;
}

.name-banner-Labour {
  background-color: red;
  color: white;
}

.name-banner-SDLP {
  background-color: green;
  color: white;
}

.name-banner-Lettuce {
  background-color: rgb(60, 153, 60);
  color: white;
}

.name-banner-SF {
  background-color: greenyellow;
  color: orangered;
}

.name-banner-LiberalDemocrat, .name-banner-Alliance {
  background-color: #faa61a;
  color: #151721
}

.name-banner-SNP {
  background-color: yellow;
  color: black;
}

.name-banner-Alba {
  background-color: blue;
  color: white;
}

.name-banner-Reform {
  background-color: #6FC0D0;
  color: black;
}

.name-banner-Independent {
  background-color: grey;
  color: white;
}

.name-banner-PC {
  background-color: green;
  color: white;
}

.name-banner-Reclaim {
  background-color: darkblue;
  color: white;
}

.name-banner-DUP {
  background-color: darkred;
  color: white;
}

.mp-name, .mp-constituency {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  opacity: 100%;
  text-align: center;
  font-family: "PT Sans Narrow", sans-serif;
}

.mp-name {
  font-weight: 800;
  font-size: 1.3rem;
}

.buttons-section {
  width: 250px;
  /* height: 85vh; */
  margin-top: 50px;
  margin-right: 10px;
}

.button {
  width: 15vw;
  height: 50px;
  border: 1px pink solid;
}

.button-spacer {
  height: 30px;
}

/* CSS */
.button-53 {
  background-color: #227683;
  border: 0 solid #E5E7EB;
  box-sizing: border-box;
  color: hsl(0, 0%, 100%);
  display: flex;
  font-family: "Bevan";
  font-size: 0.8rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1.75rem;
  padding: .75rem 1.65rem;
  position: relative;
  text-align: center;
  text-decoration: none #000000 solid;
  text-decoration-thickness: auto;
  width: 80%;
  max-width: 460px;
  position: relative;
  cursor: pointer;
  transform: rotate(-2deg);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-53:focus {
  outline: 0;
}

.button-53:after {
  content: '';
  position: absolute;
  border: 2px solid #acacac;
  bottom: 2px;
  left: 2px;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
}

.button-53:hover:after {
  bottom: 2px;
  left: 2px;
}

.live-game-button {
  color: green
}

.win-banner {
  z-index: 100;
  height: 80vh;
  width: 80vh;
  margin: auto;
  position: absolute;
  top: 10vh;
  left: 35vw;
}

.hidden {
  display: none;
}

.not-hidden {
  background-color: #3DD1E7;
}

.win-banner-image {
  height: 100%;
  width: 100%;
}

.hide-stamp {
  display: none;
}

.show-stamp {
  display: block;
}

@media (min-width: 768px) {
  .button-53 {
    padding: .75rem 3rem;
    font-size: 1.25rem;
  }
}


